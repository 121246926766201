import * as React from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { BlockRenderer, Layout, TrilingualString } from '@components'
import { typeStyle } from '@theme'


interface LegalPageProps {
  data: {
    sanityLegalPage: GatsbyTypes.SanityLegalPage
  }
}

const PrivacyPage = ({ data: { sanityLegalPage: {
  title,
  _rawLocalizedTitle,
  _rawCopy,
} } }: LegalPageProps) => (
  <Layout>
    <Helmet>
      <title>Bawi Agua Fresca: {title}</title>
    </Helmet>
    <Whitespace>
      <Headline><TrilingualString>{_rawLocalizedTitle}</TrilingualString></Headline>
      {_rawCopy && (
        <BlockRenderer>{_rawCopy!}</BlockRenderer>
      )}
    </Whitespace>
  </Layout>
)


export const Headline = styled.h1`
  ${typeStyle.header}
  margin-bottom: 1em;
`


export const Whitespace = styled.main`
  padding: 30px 20px;
  @media only screen and (min-width: 744px) {
    padding: 6vw 8vw;
  }
  @media only screen and (min-width: 1440px) {
    padding: 86px 115px;
  }
`



export const query = graphql`
  query PrivacyPageQuery {
    sanityLegalPage(slug: {current: {eq: "privacy-policy"}}) {
      ...legalPageFields
    }
  }
`


export default PrivacyPage
